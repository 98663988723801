import HeaderCta from 'components/Header/HeaderCta'
import styles from './FooterSticky.module.scss'
import { Button } from '@achieve/ascend'
import { MediaImageStandard, Typography } from 'components/Contentful'
import { AchieveLink } from 'components/AchieveLink'
import { PhoneDataContext } from 'providers/PhoneDataProvider'
import { useContext, useEffect, useState } from 'react'
import useLayoutMenuCondensed from 'hooks/useLayoutMenuCondensed'
import { get as _get } from 'lodash-es'
import { FEATURE_TOGGLES } from 'constants/featureToggles'
import { checkIfExperimentIsOn } from 'utils/experiments'
import { useFeatureFlag } from 'hooks/useFeatureFlag'
import { useRouter } from 'next/router'
import { useGraduate } from 'providers/GraduateProvider'
import { LayoutContext } from 'providers/LayoutProvider'
import { FdrPage } from 'utils/environmentConfig'
import { WALL_OF_FAME_ROUTE } from 'constants/navigation'

export default function FooterSticky({
  specificHeaderCtaDataFiltered,
  headerContent = {},
  content,
}) {
  let phoneData = useContext(PhoneDataContext)
  const [menuCondensed] = useLayoutMenuCondensed()
  const usesStickyFooter = useFeatureFlag(FEATURE_TOGGLES.ACX_STICKY_FOOTER_HEADER)
  const StickyFooterExperimentOn = checkIfExperimentIsOn(usesStickyFooter)
  const router = useRouter()
  const { graduate } = useGraduate()

  const pathsNotToShow = ['/', '/l/[slug]', '/a/[slug]']
  const specificPathsNotToShowStickyFooter = [WALL_OF_FAME_ROUTE]
  const { link = {} } = headerContent
  const tp_text = _get(content, 'card.subtitle')
  const tp_image = _get(content, 'card.image')
  const { state: { siteEnv } = {} } = useContext(LayoutContext)

  const callToAssociatedNumber = () => {
    if (phoneData?.phoneNumber) window.open(`tel:${phoneData.phoneNumber}`, '_self')
  }

  const [visible, setVisible] = useState(menuCondensed)

  useEffect(() => {
    if (menuCondensed) {
      setVisible(true)
    } else {
      setTimeout(() => setVisible(false), 300)
    }
  }, [menuCondensed])

  if (
    (siteEnv === FdrPage ? false : !StickyFooterExperimentOn) ||
    pathsNotToShow.includes(router.pathname) ||
    (graduate && specificPathsNotToShowStickyFooter.includes(router.pathname))
  )
    return null

  return (
    visible && (
      <div
        className={`block lg:hidden bg-white text-white p-4 fixed bottom-0 w-full flex flex-col z-10 shadow-inner
      ${menuCondensed ? 'animate-slideDown' : 'animate-slideUp'}`}
      >
        <div className="grid grid-cols-5 gap-2">
          <div className="col-span-2">
            {phoneData?.phoneNumber && (
              <AchieveLink
                noLink
                track={{
                  list_name: 'CALL STICKY FOOTER',
                  click_id: phoneData?.phoneNumber,
                  click_text: `Call us`,
                  click_url: phoneData?.phoneNumber,
                  click_type: 'Call us Click',
                  event_action: 'button_click',
                  nav_link_section: 'Sticky Footer',
                  track_event: 'call-sticky-footer',
                }}
                data-testid="call-sticky-footer"
              >
                <Button
                  color="secondary"
                  variant="contained"
                  className="w-full"
                  onClick={() => callToAssociatedNumber()}
                >
                  <Typography variant="displayXs" fontWeight="bold" content="Call us" />
                </Button>
              </AchieveLink>
            )}
          </div>
          <div className="col-span-3">
            <HeaderCta
              linkUrl={specificHeaderCtaDataFiltered ?? link.fields?.linkHref}
              linkText={link.fields?.linkText?.content[0]?.content[0]?.value}
              size="large"
              className={styles['header-cta']}
              data-testid="header-get-started-button"
              variantText="displayXs"
              event={{
                list_name: 'GET STARTED STICKY FOOTER',
                nav_link_section: 'Sticky Footer',
                track_event: 'get-started-sticky-footer',
              }}
            />
          </div>
        </div>
        <div className="mt-2 flex flex-row gap-2 items-center justify-center">
          <div className="w-[165px] h-[20px]">
            <MediaImageStandard content={tp_image} />
          </div>
          <Typography variant="bodyXs" className="text-black" content={tp_text} />
        </div>
      </div>
    )
  )
}
